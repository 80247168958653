import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseOutlined from "@ant-design/icons";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Divider,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import { useHistory } from 'react-router';

// project import
// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";

// assets
import { useDispatch, useSelector } from "react-redux";

// actions
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import { toast } from "react-toastify";
import _ from "lodash";


const FIAT = ["EUR", "USD"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined style={{ color: "#000" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewTransaction({
  wallet,
  isEdition,
  actionButton,
  handleSuccess,
  wallets,
  bankDetails,
}) {
  const [open, setOpen] = React.useState(false);
  const [qrCode, setQrCode] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const {
    login_request,
    auth: { user },
  } = useSelector((state) => state.userAuth);


  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{actionButton()}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isEdition ? "Edit position" : "New position"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              pWallet: wallet?.wallet?.id ?? "",
              amount: wallet?.amount ?? "",
              bankDetails: wallet?.account_details ?? "",
              type: wallet?.type ?? "", 
              walletAddress: wallet?.wallet_address ?? "0",
              relationship_with_crypto_receiver: wallet?.relationship_with_crypto_receiver ?? "",
              accountManagerNotes: wallet?.note ?? "",
              userNotes: wallet?.transaction_notes ?? "",
              status: wallet?.status ?? "VALIDATED",
              accept: wallet?.user_accept_risk ?? false,
              // accountManagerNotes: wallet?.note ?? "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              pWallet: Yup.string().max(255).required("This field is required"),
              amount: Yup.string().max(255).required("This field is required"),
              type: Yup.string().required("This field is required"),
              // positionType: Yup.string().required("This field is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                // const data = { ...values, type: "USER", user:  userId};
                const data = {
                  wallet: values.pWallet,
                  amount: values?.amount,
                  account_details: values?.bankDetails,
                  type: values?.type, 
                  wallet_address: values?.walletAddress,
                  relationship_with_crypto_receiver: values?.relationship_with_crypto_receiver,
                  note: values?.accountManagerNotes,
                  transaction_notes: values?.userNotes,
                  status: values?.status,
                  created_by: user.id,
                  user_accept_risk: values?.accept ?? false,
                };

                if (wallet && isEdition) {
                  API.patch(URLS.TRANSACTIONS.EDIT(wallet?.id), data)
                    .then((reps) => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      if (handleSuccess) {
                        handleSuccess(reps.data);
                      }
                      setLoading(false);
                      handleClose();
                      toast.success("Position edited successfuly");
                    })
                    .catch((err) => {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                      setLoading(false);
                    });
                } else {
                  API.post(URLS.TRANSACTIONS.CREATE, data)
                    .then((reps) => {
                      setStatus({ success: true });
                      setSubmitting(false);
                      if (handleSuccess) {
                        handleSuccess(reps.data);
                      }
                      setLoading(false);
                      handleClose();
                      toast.success("Position created successfuly");
                    })
                    .catch((err) => {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                      setLoading(false);
                    });
                }
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="pWallet-login">Wallet</InputLabel>
                      <Select
                        id="pWallet-login"
                        // type="email"
                        value={values.pWallet}
                        name="pWallet"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(touched.pWallet && errors.pWallet)}
                      >
                        {wallets.map((wal) => {
                          return (
                            <MenuItem key={wal.id} value={wal.id}>
                              {wal.balance} {wal.currency}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value="ETH">Ethereum</MenuItem>
                        <MenuItem value="USDT">Tether</MenuItem>
                        <MenuItem value="USD">United states dollar</MenuItem>
                        <MenuItem value="EUR">Euro</MenuItem> */}
                      </Select>
                      {touched.pWallet && errors.pWallet && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-pWallet-login"
                        >
                          {errors.pWallet}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="type-login">Type</InputLabel>
                      <Select
                        id="type-login"
                        // type="email"
                        value={values.type}
                        name="type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Currency"
                        fullWidth
                        error={Boolean(touched.type && errors.type)}
                      >
                        <MenuItem value="CREDIT">DEPOSIT</MenuItem>
                        <MenuItem value="WITHDRAW">WITHDRAW</MenuItem>
                      </Select>
                      {touched.type && errors.type && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-type-login"
                        >
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="amount-login">Amount</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.amount && errors.amount)}
                        id="amount-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.amount}
                        type={"number"}
                        name="amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Transaction amount"
                      />
                      {touched.amount && errors.amount && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-amount-login"
                        >
                          {errors.amount}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="type-status">Status</InputLabel>
                      <Select
                        id="type-status"
                        // type="email"
                        value={values.status}
                        name="type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Status"
                        fullWidth
                        error={Boolean(touched.status && errors.status)}
                      >
                        <MenuItem value="AWAITING_FOR_APPROVAL">Awaiting for approval</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="VALIDATED">Validated</MenuItem>
                        <MenuItem value="ON_HOLD">On Hold</MenuItem>
                        <MenuItem value="CANCELED">Canceled</MenuItem>
                      </Select>
                      {touched.status && errors.status && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-type-status"
                        >
                          {errors.status}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {
                    FIAT.includes(_.filter(wallets, {id: values.pWallet})[0]?.currency) ? 
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="bankDetails-login">
                        Bank details
                      </InputLabel>
                      <Select
                        id="bankDetails-login"
                        // type="email"
                        value={values.bankDetails}
                        name="bankDetails"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Bank details"
                        fullWidth
                        error={Boolean(
                          touched.bankDetails && errors.bankDetails
                        )}
                      >
                        {bankDetails.map((wal) => {
                          return (
                            <MenuItem key={wal.id} value={wal.id}>
                              {wal.bank_name} ({wal.bank_account_name})
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value="ETH">Ethereum</MenuItem>
                        <MenuItem value="USDT">Tether</MenuItem>
                        <MenuItem value="USD">United states dollar</MenuItem>
                        <MenuItem value="EUR">Euro</MenuItem> */}
                      </Select>
                      {touched.bankDetails && errors.bankDetails && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-bankDetails-login"
                        >
                          {errors.bankDetails}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  :
                        <>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="walletAddress-login">
                        Wallet address
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.walletAddress && errors.walletAddress
                        )}
                        id="walletAddress-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.walletAddress}
                        type="text"
                        name="walletAddress"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Crypto wallet address"
                      />
                      {touched.walletAddress && errors.walletAddress && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-walletAddresslogin"
                        >
                          {errors.walletAddress}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="relationship_with_crypto_receiver-login">
                        Relationship with crypto receiver
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.relationship_with_crypto_receiver &&
                            errors.relationship_with_crypto_receiver
                        )}
                        id="relationship_with_crypto_receiver-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.relationship_with_crypto_receiver}
                        type="text"
                        min={0}
                        name="relationship_with_crypto_receiver"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Relationship with crypto receiver"
                      />
                      {touched.relationship_with_crypto_receiver &&
                        errors.relationship_with_crypto_receiver && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-relationship_with_crypto_receiver-login"
                          >
                            {errors.relationship_with_crypto_receiver}
                          </FormHelperText>
                        )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.accept}
                            onChange={(event) =>
                              setFieldValue("accept", event.target.checked)
                            }
                            name="accept"
                            color="primary"
                            size="small"
                          />
                        }
                        label={
                          <Typography variant="h6">User accept risk</Typography>
                        }
                        e
                      />
                    </Stack>
                  </Grid>
                        </>
                  }
                  <Grid item md={12} xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="accountManagerNotes-login">
                        Account manager notes
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.accountManagerNotes &&
                            errors.accountManagerNotes
                        )}
                        id="accountManagerNotes-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.accountManagerNotes}
                        type="text"
                        multiline
                        rows={4}
                        name="accountManagerNotes"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Account manager notes"
                      />
                      {touched.accountManagerNotes &&
                        errors.accountManagerNotes && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-accountManagerNotes-login"
                          >
                            {errors.accountManagerNotes}
                          </FormHelperText>
                        )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="userNotes-login">
                        User notes
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.userNotes && errors.userNotes)}
                        id="userNotes-login"
                        // type={showPassword ? 'text' : 'password'}
                        value={values.userNotes}
                        type="text"
                        multiline
                        rows={4}
                        name="userNotes"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Notes by the user"
                      />
                      {touched.userNotes && errors.userNotes && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-userNotes-login"
                        >
                          {errors.userNotes}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ mt: "0" }}></Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ height: "0px", visibility: "hidden" }}
                  >
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={login_request}
                        id="submitButton"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                        {/* {login_request && <CircularLo /> } */}
                      </Button>
                    </AnimateButton>
                  </Grid>
                  {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              const submitButton = document.getElementById("submitButton");
              if (submitButton) {
                submitButton.click();
              }
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
