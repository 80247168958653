import * as React from "react";
import {
  Stack,
  Box,
  OutlinedInput,
  Grid,
  Button,
  Typography,
  Autocomplete,
  InputLabel,
  TextField,
  FormHelperText,
  Divider,
  ButtonGroup,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// import FirebaseSocial from './FirebaseSocial';
import AnimateButton from "components/@extended/AnimateButton";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
// Icons
import { EditOutlined } from "@ant-design/icons";
import { API } from "services/axios";
import { URLS } from "services/urls/urls";
import HideIfNotAdmin from "components/auth/HideIfNotAdmin";
import ResetPassword from "./ResetPassword";
import DeleteUSerAccount from "./DeleteUserAccount";

export default function UpdatePersonnal({ prospect, groups, refetchUser }) {
  const [resetingPW, setResetingPW] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [verifyingAccount, setVerifyingAccount] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [country, setCountry] = React.useState(prospect?.country);

  const {
    auth: { user },
  } = useSelector((state) => state.userAuth);
  const { loading_countries_request, countriesList } = useSelector(
    (state) => state.countries
  );
  const handleVerifyAccount = () => {
    if(!prospect?.account_verified){
      setVerifyingAccount(true)

      API.put(URLS.AUTHS.VERIFY_ACCOUNT(prospect?.id), {...prospect, account_verified: !prospect?.account_verified}).then((reps) => {
        toast.success("Account successfully verified");
        setVerifyingAccount(false);
        if(refetchUser) {
          refetchUser()
        }
      }).catch((error) => {
        setVerifyingAccount(false)
      })
    }
  }

  const handleResetPassword = () => {
    setResetingPW(true);
    API.post(URLS.AUTHS.resetPassword, { email: prospect.email })
      .then((resp) => {
        toast.success("The user must check his mail box to reset password!");
        setResetingPW(false);
      })
      .catch((error) => {
        setResetingPW(false);
      });
  };

  const handleLock = () => {
    setResetingPW(true);
    API.put(URLS.USERS.LOCK_UNLOCK_USER(prospect.id), { ...prospect, is_active: !prospect?.is_active })
      .then((resp) => {
        toast.success(prospect?.is_active ? "Account locked!" : "Account unlocked!");
        if(refetchUser){
          refetchUser()
        }
        setResetingPW(false);
      })
      .catch((error) => {
        setResetingPW(false);
      });
  };

  const canEdit = true;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      //     // Set country
      //     const country = _.filter(countriesList, (item) => item.url.includes(`/${prospect.country}/`))[0];
      //     setCountry(country);
      //     if (prospect.countryDetails) {
      //         setCountry(prospect.countryDetails);
      //     }
    }
  }, [open]);

  React.useEffect(() => {
    if (prospect?.country) {
      setCountry(prospect?.country);
    }
  }, []);

  return (
    <div>
      <Grid
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <Formik
          initialValues={{
            email: prospect.email ?? "",
            firstName: prospect.first_name ?? "",
            lastName: prospect.last_name ?? "",
            phoneNumber: prospect.phone_number ?? "",
            country: prospect.country ?? "",
            address: prospect.address ?? "",
            gender: prospect.gender ?? "",
            language: prospect.language ?? "",
            zip: prospect.zip_code ?? "",
            accountType: prospect.account_type ?? "",
            submit: null,
          }}
          //   validationSchema={Yup.object().shape({
          //     email: Yup.string()
          //       .email("Must be a valid email")
          //       .max(255)
          //       .required("Email is required"),
          //     firstName: Yup.string().max(255).required("First name is required"),
          //     lastName: Yup.string().max(255).required("Last name is required"),
          //     phoneNumber: Yup.string().max(255).required("Phone is required"),
          //   })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setStatus({ success: false });
            setSubmitting(false);
            const {
              email,
              firstName,
              lastName,
              phoneNumber,
              address,
              gender,
              language,
              zip,
              accountType,
            } = values;
            if (country) {
              setIsEditing(true);
              let countryId = "";
              if (country.id) {
                countryId = country.id;
              } else {
                countryId = country.url.split("/");

                countryId = countryId[countryId.length - 2];
              }
              const toSend = {
                ...prospect,
                country: countryId,
                city: undefined,
                region: undefined,
                email,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                address,
                gender,
                language,
                zip_code: zip,
                account_type: accountType,
                team: prospect.team ? prospect?.team.id : undefined,
                managed_by: prospect.managed_by ? prospect?.managed_by.id : undefined,
                groups: prospect.groups ? prospect.groups?.map(grp => grp.id) : [],

              };
              API.put(URLS.USERS.EDIT(prospect.id), toSend).then((resp) => {
                toast.success("User informations successfully updated");
                setIsEditing(false);
                if(refetchUser){
                  refetchUser()
                }
              }).catch((error) => {
                toast.error("Account update failed");
              setIsEditing(false);
              });
            } else {
              toast.error("Please choose a country and try again...");
              setIsEditing(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">First name</InputLabel>
                    <OutlinedInput
                      id="firstName"
                      type="text"
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter First name"
                      fullWidth
                      disabled={!canEdit}
                      error={Boolean(touched.firstName && errors.firstName)}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Last name</InputLabel>
                    <OutlinedInput
                      id="lastName"
                      type="text"
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!canEdit}
                      placeholder="Enter First name"
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      id="email-login"
                      type="email"
                      value={values.email}
                      name="email"
                      disabled={!canEdit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email address"
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Phone number</InputLabel>
                    <OutlinedInput
                      id="phoneNumber"
                      type="text"
                      value={values.phoneNumber}
                      name="phoneNumber"
                      disabled={!canEdit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Phone number"
                      fullWidth
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.phoneNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Country</InputLabel>
                    <FormControl size="small">
                      <Autocomplete
                        id="country-select-demo"
                        options={countriesList}
                        value={country}
                        size="small"
                        disabled={!canEdit}
                        autoHighlight
                        loading={loading_countries_request}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          setCountry(newValue);
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code2.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code2.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.code2}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={!canEdit}
                            //   label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {touched.phoneNumber && errors.phoneNumber && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.phoneNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="pd-address">Address</InputLabel>
                    <OutlinedInput
                      id="pd-address"
                      type="text"
                      value={values.address}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Address"
                      fullWidth
                      error={Boolean(touched.address && errors.address)}
                    />
                    {touched.address && errors.address && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-address-login"
                      >
                        {errors.address}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                <Grid item md={3} sm={4} xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="gender-select">Gender</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="gender-select-label"
                        id="gender-select"
                        value={values.gender}
                        name="gender"
                        // label="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.gender && errors.gender)}
                      >
                        {["MALE", "FEMALE"].map((item, index) => (
                          <MenuItem
                            key={"menu-item-gender-" + index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {touched.gender && errors.gender && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.gender}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="gender-language">Language</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="language-select-label"
                        id="language-select"
                        value={values.language}
                        name="language"
                        // label="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.gender && errors.gender)}
                      >
                        {[
                          {
                            label: "Francais",
                            value: "FR",
                          },
                          {
                            label: "English",
                            value: "EN",
                          },
                        ].map((item, index) => (
                          <MenuItem
                            key={"menu-item-gender-" + index}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {touched.language && errors.language && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-language-login"
                      >
                        {errors.language}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="zip-language">ZIP</InputLabel>
                    <OutlinedInput
                      id="zip"
                      type="text"
                      value={values.zip}
                      name="zip"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Zip code"
                      fullWidth
                      error={Boolean(touched.zip && errors.zip)}
                    />
                    {touched.zip && errors.zip && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-zip"
                      >
                        {errors.zip}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="account-type-select">
                      Account type
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="account-type-select-label"
                        id="account-type-select"
                        value={values.accountType}
                        name="accountType"
                        // label="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.accountType && errors.accountType
                        )}
                      >
                        {[
                          "STARTER",
                          "CLASSIC",
                          "INTERMEDIATE",
                          "ADVANCED",
                          "ULTIMATE",
                        ].map((item, index) => (
                          <MenuItem
                            key={"menu-item-account-type-" + index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {touched.accountType && errors.accountType && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.accountType}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {/* <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Message</InputLabel>
                    <OutlinedInput
                      id="message"
                      type="text"
                      value={values.message}
                      name="message"
                      multiline
                      minRows={6}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Write a note for this prospect"
                      fullWidth
                      error={Boolean(touched.message && errors.message)}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.message}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid> */}
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ visibility: "hidden" }}
                      id="submit"
                    >
                      {/* {login_request && <CircularLo /> } */}
                    </Button>
                  </AnimateButton>
                </Grid>
                {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
      <Box mb={4}>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <LoadingButton
          loading={isEditing}
          onClick={() => {
            let submitBtn = document.getElementById("submit");
            if (submitBtn) {
              submitBtn.click();
            }
          }}
          variant="contained"
          color="primary"
        >
          Update
        </LoadingButton>
      </Box>
      <HideIfNotAdmin>
        <Divider />
        <Grid mb={4}>
          <Divider />
          <Typography variant="h3" mt={3}>
            Account verification
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack mt={2}>
              {/* <Typography>Is user active</Typography> */}
              <LoadingButton
              loading={verifyingAccount}
                variant="contained"
                size={"medium"}
                mb={"auto"}
                onClick={handleVerifyAccount}
                color={prospect?.account_verified ? "success" : "error"}
              >
                {!prospect?.account_verified ? "Verify account" : "Account verified"}
              </LoadingButton>
            </Stack>
          </Stack>
        </Grid>
      </HideIfNotAdmin>
      <HideIfNotAdmin>
      <Divider />
          <Grid mb={4}>
            <Divider />
            <Typography variant="h3" mt={3}>
              User Access
            </Typography>
            <Stack direction="row" spacing={2}>
              <Stack mt={2}>
                {/* <Typography>Is user active</Typography> */}
                <LoadingButton
                loading={resetingPW}
                  sx={{ width: "100px" }}
                  variant="contained"
                  onClick={handleLock}
                  size={"medium"}
                  mb={"auto"}
                  color={prospect?.is_active ? "error" : "success"}
                >
                  {prospect?.is_active ? "Lock user" : "Activate user"}
                </LoadingButton>
              </Stack>
            </Stack>
          </Grid>
        </HideIfNotAdmin>
      <HideIfNotAdmin>
      <Divider />
        <Grid mb={4}>
          <Divider />
          <Typography variant="h3" mt={3}>
            Account security
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack mt={2}>
              <ResetPassword user={prospect} />
            </Stack>
          </Stack>
        </Grid>
      </HideIfNotAdmin>
      <HideIfNotAdmin>
      <Divider />
        <Grid mb={4}>
          <Divider />
          <Typography variant="h3" mt={3}>
            Account deletion <small>(This action is not reversible)</small>
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack mt={2}>
              <DeleteUSerAccount user={prospect} />
            </Stack>
          </Stack>
        </Grid>
      </HideIfNotAdmin>
    </div>
  );
}
